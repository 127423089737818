// Fonts
$font-default: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-base: var(--font-primary), sans-serif;
$font-primary: $font-base;
$font-secondary: var(--font-secondary), sans-serif;
$font-tertiary: $font-base;

$preferred_font: $font-base;

$font-sans: $font-primary;
$font-serif: $font-primary;
$font-coursive: $font-secondary;
$font-condensed: $font-secondary;

$font-text: $font-secondary;
$font-title: $font-primary;
$font-feature: $font-secondary;
$font-button: $font-primary;

$font-size: var(--font-size);
$font-w: 400;
$font-lh: 1.334;

// Grids
$container-width: var(--container); 


// Devices
$mobile-large: 480px;
$mobile: 420px;
$mobile-small: 350px;

$tablet-large: 1023px;
$tablet: 992px;
$tablet-small: 720px;

$desktop-large: 1720px;
$desktop: 1240px;
$desktop-small: 1024px;

$start-mobile: 1020px;

// Shadows
$shadow-inset: inset 0 2px 2px 0 rgba(0,0,0,.14);
$shadow-0: 0 0 1px rgba(0, 0, 0, 0);
$shadow-2: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
$shadow-3: 0 3px 4px 0 rgba(0,0,0,.14),0 3px 3px -2px rgba(0,0,0,.2),0 1px 8px 0 rgba(0,0,0,.12);
$shadow-4: 0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12),0 2px 4px -1px rgba(0,0,0,.2);
$shadow-6: 0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12),0 3px 5px -1px rgba(0,0,0,.2);
$shadow-8: 0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12),0 5px 5px -3px rgba(0,0,0,.2);